/* Estilo geral para o campo de data */
input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 7px 6px;
  font-size: 16px;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  margin: 0px;
}

/* Estilo ao passar o mouse */
input[type="date"]:hover {
  border-color: #888;
}

/* Estilo ao focar no campo */
input[type="date"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Preservando o ícone do calendário */
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(20%);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
  opacity: 1;
}

/* Remove as setas de incremento do campo de data */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}


.planame {
  font-size: 18px;
}


.plandesc {
  font-size: 14px;
  font-weight: 600;
}

.plansuf {
  font-size: 16px;
  /* Tamanho da fonte menor para o texto interno */
  vertical-align: baseline;
  /* Alinha com a linha de base do texto */
  margin-left: 4px;
  /* Ajuste de margem para separação da vírgula */
}

.planprice {
  font-size: 24px;
  line-height: 1.2;
  display: flex;
  text-align: left;
  font-weight: 700;
  align-items: baseline;
  justify-content: flex-start;
}

.smaller-text {
  font-size: 12px;
  /* Tamanho da fonte menor para o texto interno */
  vertical-align: baseline;
  /* Alinha com a linha de base do texto */
  margin-left: 4px;
  /* Ajuste de margem para separação da vírgula */
}


.flexcolumnleft {
  font-family: Montserrat;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.max_wd {
  max-width: 1140px;
}


/* Contêiner para os planos */
.plan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  font-family: 'Arial', sans-serif;
}

/* Contêiner para o toggle (Semestral/Anual) */
.toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 25px;
}

/* Estilo base para os checkboxes, que são escondidos */
.custom-switch {
  display: none;
}

.label1 {
  position: relative;
  font-size: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #333;
  padding: 10px;
  padding-right: 0px;
  padding-left: 50px;
}

/* Estilo para a "chave" da switch */
.label1:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s;
}

/* Estilo para a "bola" da switch */
.label1:after {
  content: '';
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 0.3s;
}

/* Estilo para o estado "checked" do switch */
.custom-switch:checked+.label1:before {
  background-color: #e7395e;
}

.custom-switch:checked+.label1:after {
  left: 21px;
}

/* Contêiner para os cartões de planos */
.plan-cards {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
  flex-direction: row;
}

/* Estilo para cada cartão de plano */
.plan-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.33% - 20px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  flex-direction: column;
  align-items: flex-start;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.plan-card h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}


.plan-card ul {
  list-style: none;
  padding: 0;
  text-align: left;
  color: #666;
  font-size: 0.9em;
}

.plan-card li {
  margin: 4px;
  display: flex;
  align-items: center;
}

.plan-card li::before {
  content: '✔';
  margin-right: 10px;
  color: #e7395e;
}

.plan-card button {
  padding: 10px 20px;
  background-color: #e7395e;
  color: #fff;
  border: none;
  font-family: 'Montserrat';
  font-weight: 600;
  border-radius: 100px;
  cursor: pointer;

  font-size: 1em;
  transition: transform 0.3s ease;
}

.plan-card button:hover {
  transform: scale(1.1);
}









.btn-modified {
  background-color: #343434;
  /* Cor verde para indicar que o botão está ativo */
  color: white;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.margin_c {
  margin: auto;
  width: fit-content;
}






.m10bot {
  margin-bottom: 10px;
}



.btn-modified:hover {
  background-color: #343434;
  /* Cor verde para indicar que o botão está ativo */
  color: white;
  transform: scale(1.1);


}




.btn-black-un[disabled] {
  background-color: #343434;
  color: #fff;
  opacity: 0.3;
  cursor: not-allowed;
}



.btn-black-un {
  border: none;

  border-radius: 28px;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: 12px 24px;
  gap: 10px;
  font-size: 14px;
  transition: transform 0.3s ease;

}

.jcenter {
  justify-content: center;
}

.grafite-bg {
  background-color: #343434;
}

.hidden {
  display: none;
}

.success-message {
  display: flex;
  gap: 5px;
  justify-content: center;
  text-align: -webkit-center;
  margin-bottom: 10px;
  padding: 15px;
  flex-direction: column;
}

.center30 {

  max-width: 30%;
  align-items: center;
  text-align: center;

}

.stext {
  font-size: 14px;
}

.fechar {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 20px;
}

.tlef {
  text-align: left !important;
}

.suporte-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ddd;
  padding: 30px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-content: center;
  font-family: Montserrat;
  height: 4vh;
}

.suporte-text {
  font-weight: bold;
  font-size: 18px;
  font-family: Montserrat;


}

.font14 {
  font-size: 14px !important;
}




.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.69);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}



.imagepost img {
  border-radius: 6px;
  max-width: 250px;
  max-height: 180px;
  object-fit: cover;
  min-width: 250px;
  min-height: 180px;
}




.imagepostfull img {
  border-radius: 6px;
}

.bold {
  font-weight: 700;
}

.textop {
  font-size: 12px;
  color: #343434;
  margin-bottom: -6px;
}

.widfit {
  width: fit-content;
}

.shared-message {
  background-color: #daf8e6;
  padding: 20px;
  border-radius: 10px;
  width: fit-content;
}


.modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px #0000004d;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.modal2 {
  font-weight: 400;
  gap: 15px;
  justify-content: center;
  max-width: 27vw;
  text-align: center;
  background-color: #ffff;
  padding: 100px;
  border-radius: 10px;
}



.modal h3 {
  margin-top: 0;

}

.modal-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

video {
  border-radius: 8px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #ff0000;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #dddddd;
}


.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}



.perfil-completo img {
  width: 150px;
  /* ajuste o tamanho da imagem conforme necessário */
  border-radius: 50%;
  /* para tornar a imagem redonda */
  margin-bottom: 10px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}


.select-container {
  position: relative;
  display: inline-block;
}


.select-container::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;

}

select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  background-color: #eeeeee;
  font-family: montserrat;
}



select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
}

.custom-icon {
  width: 25px;
}

.custom-remove-button {
  width: 50px;
  height: 50px;
  background-color: #ee6262;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  margin-bottom: 16px;
}

.custom-file-input {
  display: none;
}

.campo-form {
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #eeeeee;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 16px;
  font-family: montserrat;
}

.titulom {
  margin-bottom: 0px;

}

.ups {
  margin: 0px;
  width: fit-content;
  padding: 4px 10px;
  border-radius: 5px;
  color: #e7395e;
  font-weight: 600;
  font-size: 12px;
  border: solid;
}

.flex-space {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.inative {
  opacity: 0.5;
}


.legenda {
  background-color: #efefeb;
  padding: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;

  border-radius: 10px;
}

.funcionario-info {
  margin: 0px;
}

.legendapost {
  width: 50vw;
  height: 10vw;
  background-color: #eeeeee;
  border: none;
  resize: none;
  border-radius: 5px;
  padding: 10px;

  font-family: montserrat;
}

.videofull {
  border-radius: 5px;
  max-width: 50%;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
}

.titulopost {
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #eeeeee;
  padding: 10px;
  font-family: montserrat;
}

.custom-file-button {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0px;
}

.custom-file-icon {
  width: 35.5px;
  height: 35.5px;
}

.profile-pink-detalhe {
  width: 70px;
  border-radius: 100px;
  display: flex;
  height: 35px;
  background-color: #343434;
  margin-right: -37px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}

.ranking-conteudos {
  gap: 10px;
  display: flex;
  flex-direction: column;
}


.w100p {
  width: 100%;
  max-width: 110px;
}

.wid15 {
  width: 15px;
}

.wid12 {
  width: 12px;
}

.wid20 {
  width: 20px;
}

.tit-postrank {
  font-size: 18px;
  font-weight: 600;
}

.post-detalhe {
  width: 100px;
  border-radius: 100px;
  display: flex;
  height: 70px;
  background-color: #343434;
  margin-right: -37px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 25px;
  gap: 3px;
}

.ranking-nv {
  font-size: 20px;
  color: #eeeeee;
  font-weight: 600;
}

.ranking-nv-post {
  font-size: 25px;
  color: #eeeeee;
  font-weight: 600;
}

.profile-pink {
  display: flex;
  align-content: center;
  align-items: center;
}

.wid60 {
  width: 60px;
}

.w_fit{
  width: fit-content;
}

.resumop {
  font-size: 14px;
}

.recentes {
  
  gap: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ranking-min {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.linhadiv {
  display: flex;
  width: 1px;
  height: 58vh;
  background-color: #343434;
  margin-top: auto;
  align-items: center;
  margin-bottom: auto;
  margin-right: 2vw;
  margin-left: 2vw;
}

.profile-ranking {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.m20b {
  margin-bottom: 20px;
}

.postr {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.titpostr {
  font-size: 18px;
}


h1,
h2,
h3,
h4 {
  margin: 0;
  font-weight: normal;
}

.head-profile {
  padding: 15px;
  padding-left: 0px;
}

.contentpr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.line {
  width: 200px;
  height: 1px;
  background-color: #343434;
}


.btns-perfil {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
}


.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fundo preto com opacidade */
  z-index: 999;
  /* Colocar o overlay acima do conteúdo */
}




.popup-inner {

  overflow-y: auto;
}


.popup_midias {
  display: flex;
  position: fixed;
  top: 50%;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: rgb(255, 255, 255);
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
  flex-direction: column;
}

.b5 {
  border-radius: 5px;
}

.grid111 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  align-content: stretch;
}

.text_center {
  text-align: center;
}

.flexrow2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap: 10px;
  justify-content: center;
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner2 {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #343434;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

.w80 {
  width: 80%;

}

.btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.center {
  align-items: center;
  margin: auto;
}

.imagefull {
  border-radius: 5px;
  height: 50vh;
  min-height: 50vh;
  max-height: 50vh;
}

.flexrowcenter {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
}

.Cadastrar Colabmn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-inicio {
  display: flex;
  align-content: space-between;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.dnone {
  display: none;
}

.m0 {
  margin: 0px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.maincenter100 {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  padding: 5%;
  justify-content: center;
  font-family: 'Montserrat';
  align-items: center;
}

.btn.selected {
  background-color: #343434;
  border-radius: 28px;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  border: solid 1px #343434;
  
  font-family: montserrat;
}


.btnmin {
  background-color: #ffffff;
  border-radius: 28px;
  color: #343434;
  padding: 5px 14px;
  border: none;
  width: fit-content;
  font-size: 13px;
  font-family: montserrat;
  margin: auto;
  transition: transform 0.3s ease;
}

.btn {
  white-space: nowrap;
  background-color: #ffffff;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343434;
  
  border: solid 1px #343434;
  
  font-family: montserrat;
  cursor: pointer;
  transition: transform 0.3s ease;
  /* Suaviza a animação */
}





.btn-black:hover {
  transform: scale(1.1);
}

.large_button {
  padding: 7px 16px;
  font-size: 16px;
}

.grafite {
  background-color: #343434;
  color: #ffff;
}

.wrap {
  flex-wrap: wrap;

}

.form-container {
  display: flex;
  flex-wrap: wrap;
}

.form-field {
  flex: 1 1 45%;
  box-sizing: border-box;
  padding-right: 10px;
}

.first-row {
  flex: 1 1 45%;
}

.second-row {
  flex: 1 1 30%;
}

.pink {
  background-color: #e64b4b;
  color: #fff;
}

.btn2 {
  background-color: #ffffff;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343434;
  padding: 7px 16px;
  border: solid 1px #343434;
  font-size: 16px;
  font-family: montserrat;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.3s ease;
  flex: 2 1 calc(33.33% - 10px);
}

.f_basic{
  flex: 2 1 calc(33.33% - 10px);
}

.btn_s {

  font-size: 11px;
  padding: 5px 8px;

}

.btn-black {
  background-color: #343434;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  
  border: solid 1px #343434;

  font-family: montserrat;
  cursor: pointer;
  transition: transform 0.3s ease;
}


.btn2:hover {
  transform: scale(1.1);
  /* Faz o botão crescer ao passar o mouse */
}

.btn:hover {
  transform: scale(1.1);
  /* Faz o botão crescer ao passar o mouse */
}

.btnmin:hover {
  transform: scale(1.05);
  /* Faz o botão crescer ao passar o mouse */
}

.botao1:hover {
  transform: scale(1.1);
  /* Faz o botão crescer ao passar o mouse */
}

.selected {
  transform: none !important;

}


.icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: currentColor;
  /* Use currentColor to inherit the color from the parent */
}

.selected {
  background-color: #343434;
  /* Cor de fundo quando selecionado */
  color: #ffff;
  /* Cor do texto quando selecionado */
}

.icon {
  fill: #ffff;
  /* Cor do ícone quando selecionado */
}

.icon {
  margin-right: 8px;
  /* Ajuste a margem conforme necessário */
  width: 16px;
  /* Ajuste o tamanho conforme necessário */
  height: 16px;
  /* Ajuste o tamanho conforme necessário */
}


.btns-lateral {
  display: flex;
  flex-direction: column;
  gap: 10px;
}



.btn-red {
  border: none;
  background-color: #e64b4b;
  border-radius: 28px;
  color: #ffffff;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: 10px;
  width: fit-content;
  gap: 10px;
  font-size: 14px;
}

.gap10 {
  gap: 10px
}

.border5 {
  border-radius: 5px;
}

.tandline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  
}

body {
  color: #343434;
  font-family: Montserrat;
}

.prevprofile {
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Adicione isso ao seu arquivo de estilos CSS */

/* Adicione isso ao seu arquivo de estilos CSS */

.loading-container2 {
  background-color: rgb(255 255 255 / 0%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  padding: 0px 10px 10px 10px;
  margin: auto;
  min-height: 100px;
  max-height: 100px;
}

.loading-spinner2 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e7395e;
  border-radius: 50%;
  width: 50px;
  
  height: 50px;
  animation: spin 1s linear infinite;
}





.imagepost {
  width: 250px;
  height: 180px;
  border-radius: 6px;
  border: solid 1px #d8d8d8;
}

.loading-container {

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  /* Fundo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}



.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e7395e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.wid200 {
  width: 200px;
}

.fotoperfil {
  width: 25%;
}

.botao1 {
  padding: 5px 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  background-color: #e7395e;
  border-radius: 32px;
  color: #ffffff;
  border: solid 2px #e7395e;
  font-size: 13px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.link1 {
  color: #ffffff;
}

.cinza {
  color: #a3a3a3;
}

a {
  text-decoration: none;
}

label {
  display: block;

}

.verde {
  background-color: #d3f5cd;
  border-radius: 10px;
  padding: 15px;
  width: fit-content;
}

.edit_l {
  resize: none;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  white-space: pre-wrap;
  border: none;
  font-family: 'Montserrat';
}




input {
  width: 100%;
  padding: 10px;

  box-sizing: border-box;
  border: none;
  background-color: #dbdbdb;
  border-radius: 10px;

  font-family: 'Montserrat', sans-serif;
}

.legenda .fontnv {
  font-weight: 800;
  color: #e7395e;
}

.fontnome {
  font-size: 24px;
  font-weight: 800;
}

.dadosperfil {
  display: flex;
  gap: 5px;
  flex-direction: column;
}


.error-message {
  background-color: #ffc5c5;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}

.barranv {
  width: 200px;
  height: 16px;
  background-color: #e7395e;
  border-radius: 100px;
}





.datapr {
  color: #bababa;
}



body {
  background-color: white !important;
}



.mainfc {
  box-sizing: border-box;
  display: flex;
  padding: 8vh;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
}



.gray {
  background-color: #343434;
  color: #ffff;


}



.flexcolumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn_pink {

  background-color: #e7395e;
  border-radius: 28px;
  margin-left: 4px;
  color: #ffffff;
  padding: 5px 11px;
  border: 0px;
  font-size: 12px;
  font-family: montserrat;
  cursor: pointer;
  transition: transform 0.3s ease;

}



.border100 {
  border-radius: 100%;
}

.jcenter {

  justify-content: center;
}


.centerflex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.h30 {
  height: 30%;
  display: flex;
}

.h70 {
  height: 70%;
}

.w60 {
  width: 60%;
}

.centerflex2 {

  display: flex;
  justify-content: center;
  height: 70%;
  align-content: center;



  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.w400p {
  width: 400px;
}

.f12 {
  font-size: 14px;
}

.mx50top {
  margin-top: 50px;
}



.m20top {
  margin-top: 15px;
}

.cxlogin {
  background-color: #eeeeee;
  padding: 5%;
  min-width: 470px;
  min-height: 550px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.profilepic {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0
}

.w100 {
  width: 100%;
  overflow: auto;
}




.w90 {
  width: 90%;
}

.w74 {
  width: 74%;
}

.w30 {
  width: 30%;
}

.w10 {
  width: 10%;
}

.quebra {
  word-break: break-word;
  overflow-wrap: break-word;
}



.w70 {

  width: 70%;
  margin: auto;
  display: flex;
}

.precsenha {
  text-align: center;
  max-width: 470px;
}

.flex_top_right {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  height: 100%;
}




.w50 {
  width: 300px;
  margin: auto;
  display: flex;
}

.wh100 {
  width: 100vw;
  height: 100vh;
}

.pd15 {
  padding: 15%;
}

.nogap {
  gap: 0px;
}


.produtos-grid {
  display: flex;
  gap: 10px;
  justify-items: center;
  flex-wrap: wrap;
  
}



.react-crop {
  position: relative;
  width: 100%;
  max-width: 500px;
  /* Ajuste conforme necessário */
}

.react-crop__image {
  display: block;
  max-width: 100%;
  height: auto;
}

.max60 {
  max-width: 60vw;
}

.mt15 {
  margin-top: 15px;
}

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.field {
  box-sizing: border-box;
  padding-right: 10px;
}

.full-width {
  flex: 1 1 100%;
}

.half-width {
  flex: 1 1 45%;
}

.line20 {
  width: 136px;
  height: 3px;
  background-color: #343434;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}



h1 {
  font-size: 26px;
  font-weight: bold;
}

.react-crop__crop-selection {
  position: absolute;
  border: 1px dashed #ffffff;
  /* Cor da borda da seleção de recorte */
  box-sizing: border-box;
  z-index: 9000;
  /* Certifique-se de que a seleção de recorte esteja acima da imagem */
}

.gap20 {
  gap: 10px;
}

.m10top{

  margin-top: 10px;
}
.gap5 {
  gap: 5px;
}



.preview-file {
  position: relative;
  width: 200px;
  height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-file img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.midias-preview {

  display: flex;
  gap: 10px;

}



.previamidias {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.preview-file:hover .overlay {
  opacity: 1;
}

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.btn-icon img {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  /* Suaviza a animação */
}


.btn-icon img:hover {

  transform: scale(1.2);
}


.form-field input {
  width: 100%;

}


.line {

  width: 60%;
  background-color: #343434;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 10px;

}

.form-field label {
  display: block;
}


.form-fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-fields-container .form-field {
  width: calc(50% - 20px);
  /* 50% da largura menos a margem */
}

.textmanterlogin {
  margin: auto;
  display: flex;
}

.left_t input {
  text-align: left;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-switch {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  padding: 0px;
  border-radius: 20px;
  margin: auto;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: none !important;
}

.custom-switch:checked {
  background-color: #e7395e;
  /* Cor de fundo rosa quando marcado */
}

.custom-switch::before {
  content: "";
  position: absolute;
  width: 18px;
  /* Largura da bolinha */
  height: 18px;
  /* Altura da bolinha */
  background-color: #fff;
  /* Cor da bolinha */
  border-radius: 50%;
  /* Deixa a bolinha arredondada */
  top: 1px;
  /* Posição superior */
  left: 1px;
  /* Posição esquerda */
  transition: transform 0.3s;
  /* Transição suave para o movimento da bolinha */


}

.custom-switch:checked::before {
  transform: translateX(20px);
  /* Move a bolinha para a direita quando marcado */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  /* Ajuste o espaçamento conforme necessário */
}



p {
  margin: 0px;
}





.formcenter {
  min-width: 40vw;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: stretch;
}



.dblock {
  display: block;
}


.row-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.ups-options {
  display: flex;
  gap: 10px;
}

.validity-options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-text {
  color: #343434;
  font-size: 12px;
  background-color: #daf8e6;
  padding: 11px;
  border-radius: 8px;
}

.inline-flex {
  display: flex;
  align-items: center;
}

.inline-flex label {
  margin-left: 5px;
}

.input_tag {
  width: auto;
}

.m20tb {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m_auto {
  margin: auto;
}


/* Esconder o botão no desktop */
.mobile-suporte {
  display: none;
}




.color_svg svg {
  color: #343434;
  background: #ffff;
}


.tag-container {
  padding: 5px 10px;
  border: 1px solid #343434;
  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

}

.tag-selecionada {
  background-color: #343434;
  color: #fff;
  padding: 5px 10px;
  border: 1px solid #343434;

  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

}

.icon_text {
  white-space: nowrap;
}

.tag-nao-selecionada {
  padding: 5px 10px;
  border: 1px solid #343434;
  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

}

/* Estilos para o modo de edição */
.tag-editando {

  padding: 0px;
}


.newtag {
  padding: 7px 16px;
  font-size: 16px;
  outline: none;
  border: 1px solid #343434;
  border-radius: 100px;
  cursor: pointer;
  background-color: transparent;
  display: inline-flex;
  align-items: center;

}

.inputedit {
  padding: 7px 0px 7px 16px;
  outline: none;


}

.excluir-tag-icon {
  cursor: pointer;
  width: 10px;
  height: 10px;
  fill: #343434;
  margin-right: 10px;
}


.erro-mensagem {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}


@keyframes spin-custom {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-container-new {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-spinner-new {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin-custom 1s linear infinite;
}



/* Oculta o botão do mobile no desktop */
.mobile-suporte {
  display: none;
}

/* Para telas menores ou iguais a 1024px (tablet e mobile) */
@media only screen and (max-width: 1024px) {
  .avisotopo {
    
    font-size: 14px;
    padding: 10px 30px;
    display: flex;
    background-color: #e7395e;
    color: #fff;
    gap: 10px;
    border-radius: 10px;
    top: 5px;
    flex-direction: row;
  }

  .avisotopo p{
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 10px;
    text-align: center;
}

  .btn{
    font-size: 14px;
    padding: 5px 15px;
  }
  .btn-black {
    font-size: 14px;
    padding: 5px 15px;
  }

  .fotoperfil {
    width: 70px;
}

  .column_mo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    

    box-sizing: border-box;
  }

  .m_auto {
    margin: auto;
  }
  .m10top {
    
    margin-top: 15px;
    margin-bottom: 10px;
  }

  /* Oculta o conteúdo do desktop no mobile */
  .desktop-suporte {
    display: none;
  }

  /* Exibe o botão no mobile */
  .mobile-suporte {
    display: block;
  }

  /* Botão fixo no canto inferior direito */
  .open-popup-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000;
  }

  /* Estilos do popup */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }



  .close-popup-btn {
    margin-top: 10px;
    background-color: #dc3545;
    color: white;
  }





  .fix_b_centro {
    position: fixed;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffff;
    width: 100%;
  }

  .legendapost {
    width: 80vw;
    height: 20vw;
    background-color: #eeeeee;
    border: none;
    resize: none;
    border-radius: 5px;
    padding: 10px;
    font-family: montserrat;
  }

 
  .m10_bt{
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .flexrow_mo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
   
    flex-wrap: wrap;
  }

  .botao1 {
    padding: 5px 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #e7395e;
    border-radius: 32px;
    color: #ffffff;
    border: solid 2px #e7395e;
    font-size: 14px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }

  .produto-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: 100%;
    object-fit: cover;
}


  .produto-imagem {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

  .flexrow_mo {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }



  .imagepost {
    width: 185px;
    height: 120px;
    border-radius: 6px;
    border: solid 1px #d8d8d8;

  }



  .btn2 {
    padding: 0px;
    display: flex;
    flex-direction: column;
    border: none;
    background: none;
  }



  .btn2 svg {
    border-radius: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #343434;
    padding: 10px;
    border: solid 1px #343434;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: visible;

  }

  .suporte-container {
    bottom: 80px;
  }

  .selected svg {
    color: #fff;
    background: #343434;
  }

  .selected {
    color: #343434;
  }

  .btn2 svg:hover {
    transform: scale(1.1);
  }


  .icon_text {
    white-space: nowrap;
    font-size: 11px;
  }



  .btns-perfil .btn2:hover {
    transform: none;

  }

  .btns-perfil {
    position: fixed;
    bottom: 0px;
    min-height: 10vh;
  }

  .mobile-suporte {
    display: none;
  }




  .imagepost img {
    border-radius: 6px;
    max-width: 185px;
    max-height: 120px;
    object-fit: cover;
    min-width: 185px;
    min-height: 120px;
  }

  .btns-perfil {
    position: fixed;
    bottom: 0px;
  }

  .mnone {
    display: none;
  }





  .loading-container2 {
    width: auto;
  }

  .cxlogin {
    min-width: 70vw;
    min-height: 60vh;
  }

  .direita {
    width: 96%;
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
    margin: auto;


    box-sizing: border-box;
  }



  


  .fixed_top {
    position: fixed;
    background-color: #ffff;
    top: 0px;
    width: 100%;
    height: 10vh;
    min-height: 10vh;
    max-height: 10vh;
    box-sizing: border-box;
    padding: 10px;
    
  }

  .w50 {
    width: 250px;
  }




  .esquerda {
    min-width: 0px;
    width: 0px;
    height: 0px;
  }

  .mainfc {
    flex-direction: column;
    padding: 0px;
    max-height: 80vh;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  h2 {
    font-size: 18px;
  }

  .w100p {
    width: 100%;
  }

  .mobile_none {
    display: none;
  }

  .btns-perfil {
    display: grid;
    align-items: center;
    grid-template-rows: auto;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: #F4F4F4;
    padding: 8px;
    box-sizing: border-box;
  }


  .nowrap {
    white-space: nowrap;
  }


  .wrap {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }





 
  .fontnome {
    font-size: 18px;

    font-weight: 800;
  }

  .white_f {
    font-size: 14px;
  }

  .dadosperfil {
    gap: 2px;
  }





  .mobile-suporte {
    display: block;
  }

  /* Botão fixo no canto inferior direito no mobile */
  .open-popup-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 1000;
  }

  /* Estilos do popup */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }

  .close-popup-btn {
    margin-top: 10px;
    background-color: #dc3545;
    color: white;
  }

  .w_m100 {
    width: 100%;
  }

  .cinza_claro {
    background-color: #F4F4F4;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin: auto;
  }

  



  .white {
    color: #fff;
  }


}




/* Para telas maiores ou iguais a 1024px (desktop) */
@media (min-width: 1024px) {

  .avisotopo {
    position: fixed;
    font-size: 14px;
    padding: 10px 30px;
    display: flex;
    background-color: #e7395e;
    color: #fff;
    gap: 10px;
    border-radius: 10px;
    top: 5px;
    flex-direction: row;
  }

  .esquerda {
    max-width: 30%;
    padding: 10px;
  }

  .btn{
    font-size: 16px;
    padding: 8px 16px;
  }

  .btn-black {
    font-size: 16px;
    padding: 8px 16px;
  }
  .fotoperfil {
    width: 120px;
}

  .produto-card {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
  
  
  }
  .direitaopcs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
}


 




  .direita {
    width: 70%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 92vh;
    min-height: 92vh;
    height: 100%;
    align-items: stretch;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 10px;
}

  .d_none {
    display: none;
  }

  .produto-imagem {
    width: 150px;
    min-width: 150px;
    object-fit: cover;
    border-radius: 8px;
    height: 150px;
  }

  .listacolab {
    height: 70vh;
    overflow: auto;
    padding-right: 10px;
    
  }

}


/* Para telas entre 768px e 1024px (tablet) - substitui a regra geral para tablet */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  
}





.vh_80 {
  height: 80vh;
}



.produto-info {
  margin-top: 10px;
}

.produto-info h3 {
  font-size: 16px;

  text-align: center;
}

.produto-info p {
  font-size: 14px;
  color: #555;

}

.produto-ups {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #343434;

  text-align: center;
}


.gap5 {
  gap: 5px;
}


.historico-troca-card {
  display: flex;
  align-items: center;
  padding: 10px;

  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.historico-troca-card img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 15px;
  object-fit: cover;
}

.historico-troca-info {
  display: flex;
  flex-direction: column;
}

.historico-troca-info h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.historico-troca-info p {
  margin: 3px 0;
  font-size: 0.9rem;

}

.historico-troca-status {
  font-weight: bold;
  color: green;
}

.historico-troca-status.processando {
  color: orange;
}

.historico-troca-status.cancelado {
  color: red;
}



/* Estilos para o contêiner da imagem e o efeito de escurecimento */
.imagem-container {
  position: relative;
  width: 100%;
  display: inline-block;
}

.produto-imagem {

  transition: opacity 0.3s ease;
}



/* Escurece a imagem ao passar o mouse */
.imagem-container:hover .produto-imagem {
  opacity: 0.5;
}

/* A sobreposição do ícone de edição */
.overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Cor de fundo semitransparente */
  color: white;
  /* Cor do ícone */
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

/* Mostra o ícone quando o mouse está sobre a imagem */
.imagem-container:hover .overlay3 {
  opacity: 1;
}

.icone-edit {
  width: 30px;
}

.line300 {
  flex-grow: 1;
  height: 1px;
  background-color: #343434;
  margin-right: 5px;
}